import ClientPortalSideNav from '@/components/ClientPortalSideNav';
import { useAPI } from '@/hooks/useAPI';
import { NAV_ITEMS, NAV_ITEM_HOME } from '@/path';
import { Box } from '@mui/material';
import { useAPIUserMe } from '@verifime/api-definition';
import { usePasswordless } from '@verifime/cognito-passwordless-auth';
import { TOrganisation } from '@verifime/components';
import { useRouter } from 'next/router';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { UrlObject } from 'url';

export default function Layout({ children }: PropsWithChildren) {
  const router = useRouter();
  const { signOut } = usePasswordless();

  const signOutAndRedirect = useCallback(() => {
    signOut().signedOut.then(() => {
      router.push('/');
    });
  }, [router, signOut]);

  const { orgId } = router.query;
  const defaultOrgId = typeof orgId === 'string' ? orgId : undefined;
  const [allClientUserOrganisations, setAllClientUserOrganisations] = useState<TOrganisation[]>([]);
  const { API } = useAPI();

  const { currentUserIsAdmin: isAdmin, isLoadingUser, loadUserError } = useAPIUserMe();

  useEffect(() => {
    // if new orgs have added, this will not be refreshed and needs
    // user to log out and log in to pick up the newly added orgs.
    if (allClientUserOrganisations?.length > 0) {
      return;
    }

    if (isLoadingUser || loadUserError) {
      return;
    }

    const abortController = new AbortController();

    const getOrganisations: Promise<defs.person.Organisation[]> = isAdmin
      ? API.person.organisationResource.getOrganisation
          .request(
            {
              startIndex: 0,
              lastIndex: 999,
              orderBy: 'organisationName',
            },
            { signal: abortController.signal },
          )
          .then((organisationSearchResult: defs.person.OrganisationPageResponse) => {
            return organisationSearchResult?.itemList;
          })
      : API.person.organisationResource.getOrganisationMe.request(
          {},
          { signal: abortController.signal },
        );

    getOrganisations.then((organisations) => {
      if (!organisations || organisations.length === 0) {
        return;
      }

      const childOrgIds = organisations
        ?.filter((org) => org.children?.length == 0)
        .map((org) => org.id);

      const availableOrgs: TOrganisation[] = organisations.map((org) => {
        return {
          id: org.id,
          name: org.organisationName,
          children: org.children
            // exclude the children that is not provisioned to the client user
            .filter((org) => childOrgIds.includes(org.id))
            .map((childOrg) => ({
              id: childOrg.id,
              name: childOrg.organisationName,
              children: [],
            })),
        };
      });
      setAllClientUserOrganisations(availableOrgs);
    });

    return () => abortController.abort();
  }, [
    API.person.organisationResource.getOrganisation,
    API.person.organisationResource.getOrganisationMe,
    allClientUserOrganisations,
    isLoadingUser,
    loadUserError,
    isAdmin,
    signOutAndRedirect,
  ]);

  useEffect(() => {
    if (!defaultOrgId && allClientUserOrganisations?.length > 0) {
      const allChildOrgs = allClientUserOrganisations.map((org) => org.children).flat();

      if (allChildOrgs.length > 0) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              orgId: allChildOrgs[0].id,
            },
          },
          undefined,
          { shallow: true },
        );
      }
    }
  }, [defaultOrgId, allClientUserOrganisations, router]);

  const handleChangeCurrentOrgId = (currentOrgId: string) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          orgId: currentOrgId,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const navItemWithOrgId = (href: UrlObject, orgId: string) => {
    return {
      ...href,
      query: {
        orgId,
      },
    };
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flex: 'auto' }}>
        <ClientPortalSideNav
          navItems={NAV_ITEMS}
          defaultOrgId={defaultOrgId}
          potentialOrgs={allClientUserOrganisations || []}
          onChangeCurrentOrgId={handleChangeCurrentOrgId}
          homeNavItem={NAV_ITEM_HOME}
          doSignOut={signOutAndRedirect}
          navItemHrefProcessor={(href: UrlObject) => navItemWithOrgId(href, defaultOrgId)}
        />
        <Box
          sx={{
            minWidth: 320,
            padding: { xs: '8px' },
            flex: 1,
            paddingBottom: { xs: '64px', sm: '8px' },
          }}
        >
          {children}
        </Box>
      </div>
    </div>
  );
}
