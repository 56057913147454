import { person, defs as personDefs } from '@/services/person';

export const useAPI = () => {
  const defs = {
    person: personDefs,
  };
  const API = {
    person,
  };

  return {
    defs,
    API,
  };
};
