import { TUserResponse } from '@verifime/utils';

export default function useAPIUserMe() {
  const {
    data,
    isLoading,
    error,
  }: {
    data: TUserResponse;
    error: any;
    isLoading: boolean;
  } = API.person.userResource.getMe.useRequest();

  return {
    currentUserRoles: data?.roles,
    currentUsername: data?.userName,
    currentUserIsAdmin: data?.roles?.includes('admin'),
    isLoadingUser: isLoading,
    loadUserError: error,
  };
}
