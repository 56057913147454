import { IDENTITY_TYPES_MAP, formatToDDMMMYYYY } from '@verifime/utils';
import { TAssessmentStatus, TCountry, TOption, TRiskLevel, TState } from './types';
import { TSupportDocumentSubType, apiSchemas } from '@verifime/api-definition';
import { TInformationDisplayRows } from '@verifime/components';

export const APPLICATION_NAME = 'VerifiMe Client Portal';

export enum CountryCode {
  AU = 'AU',
  NZ = 'NZ',
}

export const AU: TCountry = { code: CountryCode.AU, label: 'Australia', phone: '61' };
export const NZ: TCountry = { code: CountryCode.NZ, label: 'New Zealand', phone: '64' };

export const COUNTRY_MAP: { [key: string]: TCountry } = { AU, NZ };

export const COUNTRIES: TCountry[] = [AU, NZ];

export const STATES: TState[] = [
  { code: 'ACT', label: 'ACT', countryCode: 'AU' },
  { code: 'NSW', label: 'NSW', countryCode: 'AU' },
  { code: 'NT', label: 'NT', countryCode: 'AU' },
  { code: 'QLD', label: 'QLD', countryCode: 'AU' },
  { code: 'SA', label: 'SA', countryCode: 'AU' },
  { code: 'TAS', label: 'TAS', countryCode: 'AU' },
  { code: 'VIC', label: 'VIC', countryCode: 'AU' },
  { code: 'WA', label: 'ACT', countryCode: 'AU' },
];

export const GENDERS: TOption[] = [
  { code: 'female', label: 'Female' },
  {
    code: 'male',
    label: 'Male',
  },
  { code: 'other', label: 'Other' },
  { code: 'notToSay', label: 'Prefer not to say' },
];

export enum EntityStatus {
  Empty = 'Empty',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export enum ErrorType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export enum ErrorScope {
  Field = 'Field',
  Form = 'Form',
  Global = 'Global',
}

export enum FormStatus {
  UNKNOWN,
  UNFILLED,
  FILLED,
  DOING_UPDATE,
}

export enum DocumentType {
  WholesaleCertificate = 'WholesaleCertificate',
  Passport = 'Passport',
  DriverLicence = 'DriverLicence',
}

export enum RiskLevel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Extreme = 'Extreme',
}

export enum AssessmentStatus {
  Open = 'Open',
  Closed = 'Closed',
  InProgress = 'InProgress',
  NoRisksDetected = 'NoRisksDetected',
}

export const AssessmentStatusOpen: TAssessmentStatus = {
  code: AssessmentStatus.Open,
  label: 'Open',
};

export const AssessmentStatusClosed: TAssessmentStatus = {
  code: AssessmentStatus.Closed,
  label: 'Closed',
};

export const AssessmentStatusNoRisksDetected: TAssessmentStatus = {
  code: AssessmentStatus.NoRisksDetected,
  label: 'No Risks Detected',
};

export const AssessmentStatusInProgress: TAssessmentStatus = {
  code: AssessmentStatus.InProgress,
  label: 'In Progress',
};

export const ASSESSMENT_STATUS: TAssessmentStatus[] = [
  AssessmentStatusOpen,
  AssessmentStatusClosed,
  AssessmentStatusNoRisksDetected,
];

export const RiskLevelLow: TRiskLevel = {
  code: RiskLevel.Low,
  label: 'Low',
};

export const RiskLevelMedium: TRiskLevel = {
  code: RiskLevel.Medium,
  label: 'Medium',
};

export const RiskLevelHigh: TRiskLevel = {
  code: RiskLevel.High,
  label: 'High',
};

export const RiskLevelExtreme: TRiskLevel = {
  code: RiskLevel.Extreme,
  label: 'Extreme',
};

export const RISK_SCORE: Record<string, number> = {
  Low: 1,
  Medium: 2,
  High: 3,
  Extreme: 4,
};

export const RISK_LEVELS: TRiskLevel[] = [
  RiskLevelLow,
  RiskLevelMedium,
  RiskLevelHigh,
  RiskLevelExtreme,
];
export enum MitigationStatus {
  Open = 'Open',
  Pass = 'Pass',
  Ignore = 'Ignore',
  Fail = 'Fail',
}

export const ASSESSMENT_STATUS_MAP = {
  [AssessmentStatus.Open]: AssessmentStatusOpen,
  [AssessmentStatus.Closed]: AssessmentStatusClosed,
  [AssessmentStatus.NoRisksDetected]: AssessmentStatusNoRisksDetected,
  [AssessmentStatus.InProgress]: AssessmentStatusInProgress,
};

export enum Comparison {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  Contains = 'Contains',
  NotContains = 'NotContains',
}

const EqualsComparisonOption: TOption = { code: 'Equals', label: 'Equals' };
const NotEqualsComparisonOption: TOption = { code: 'NotEquals', label: 'NotEquals' };
const ContainsComparisonOption: TOption = { code: 'Contains', label: 'Contains' };
const NotContainsComparisonOption: TOption = { code: 'NotContains', label: 'NotContains' };

// The comparison label is the same as the code for now. May change the label to be more readable later
export const COMPARISON_OPTIONS: TOption[] = [
  EqualsComparisonOption,
  NotEqualsComparisonOption,
  ContainsComparisonOption,
  NotContainsComparisonOption,
];

export enum RuleAttribute {
  entityType = 'entityType',
  entityStatus = 'entityStatus',
  countryCode = 'countryCode',
  wholesaleCertificateVerified = 'wholesaleCertificateVerified',
  identityDocumentVerifiedCount = 'identityDocumentVerifiedCount',
  pepDetected = 'pepDetected',
  sanctionsDetected = 'sanctionsDetected',
}

export const RULE_ATTRIBUTES: string[] = [
  RuleAttribute.entityType,
  RuleAttribute.entityStatus,
  RuleAttribute.countryCode,
  RuleAttribute.wholesaleCertificateVerified,
  RuleAttribute.identityDocumentVerifiedCount,
  RuleAttribute.pepDetected,
  RuleAttribute.sanctionsDetected,
];

type TRuleAttributeMeta = {
  comparisonList?: TOption[];
  valueList: TOption[] | string[];
};

const booleanRuleAttributeMeta: TRuleAttributeMeta = {
  comparisonList: [EqualsComparisonOption, NotEqualsComparisonOption],
  valueList: ['true', 'false'],
};

/**
 * FIXME: We need to remove these identity types from DB and code.
 */
export enum UnsupportedIdentityTypeCode {
  Charity_Association = 'Charity_Association',
  Individual_Foreign = 'Individual_Foreign',
  Private_Foreign_Company = 'Private_Foreign_Company',
  Public_Foreign_Company = 'Public_Foreign_Company',
}

const UNSUPPORTED_IDENTITY_TYPES_MAP: {
  [key in UnsupportedIdentityTypeCode]: TOption;
} = {
  [UnsupportedIdentityTypeCode.Charity_Association]: {
    code: UnsupportedIdentityTypeCode.Charity_Association,
    label: 'Charity Association',
  },
  [UnsupportedIdentityTypeCode.Individual_Foreign]: {
    code: UnsupportedIdentityTypeCode.Individual_Foreign,
    label: 'Individual (Foreign)',
  },
  [UnsupportedIdentityTypeCode.Private_Foreign_Company]: {
    code: UnsupportedIdentityTypeCode.Private_Foreign_Company,
    label: 'Private Company (Foreign)',
  },
  [UnsupportedIdentityTypeCode.Public_Foreign_Company]: {
    code: UnsupportedIdentityTypeCode.Public_Foreign_Company,
    label: 'Public Company (Foreign)',
  },
};

// FIXME: Why do we have both Charity and Charity_Association? We should just have one!
const { Charity, ...IDENTITY_TYPES_MAP_WITHOUT_CHARITY } = IDENTITY_TYPES_MAP;

export const ALL_IDENTITY_TYPES_MAP = {
  ...IDENTITY_TYPES_MAP_WITHOUT_CHARITY,
  ...UNSUPPORTED_IDENTITY_TYPES_MAP,
};

export const RULE_ATTRIBUTE_MAP: {
  [key in RuleAttribute]: TRuleAttributeMeta;
} = {
  [RuleAttribute.entityType]: { valueList: Object.values(ALL_IDENTITY_TYPES_MAP) },
  [RuleAttribute.entityStatus]: { valueList: Object.keys(EntityStatus) },
  [RuleAttribute.countryCode]: { valueList: Object.keys(COUNTRY_MAP) },
  [RuleAttribute.wholesaleCertificateVerified]: booleanRuleAttributeMeta,
  [RuleAttribute.identityDocumentVerifiedCount]: { valueList: ['1', '2', '3', '4', '5'] },
  [RuleAttribute.pepDetected]: booleanRuleAttributeMeta,
  [RuleAttribute.sanctionsDetected]: booleanRuleAttributeMeta,
};

